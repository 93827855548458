<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="Super Large Scale Gap Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Super Large Scale Gap Test Description"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/SLSGTDetailed-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/SLSGTDetailed-min.png" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The Super Large Scale Gap Test (SLSGT) can be performed as a
              substitute for the Single Package and Stack tests on a solid
              rocket-motor propellant per the US DOT. The purpose of the test is
              to determine whether a propellant will maintain a stable shock
              front, if so it’s a HD 1.1 candidate.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objective</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Ignition Booster</td>
                    <td>Provide a strong, stable shock to the main booster</td>
                    <td></td>
                    <td>
                      Detonator and (1) a Comp A-5 Booster 1 in (25 mm) diameter
                      and height with density of 1.78 g/cc and (2)a 2 in (51 mm)
                      long cylindrical with 2 in (51 mm) diameter Comp B booster
                      with density of 1.73 g/cc
                    </td>
                  </tr>
                  <tr>
                    <td>Main Booster</td>
                    <td>
                      Provide a strong, stable shock front to the top of the
                      propellant sample
                    </td>
                    <td>
                      <small
                        >1:1 L/D (cylindrical or conical) allows a basic point
                        source initiation in the center to expand
                        hemispherically, outputting a relatively planar 200+
                        kbar shock. A conical charge can produce a similar
                        effect but uses 1/3 the explosive.</small
                      >
                    </td>
                    <td>
                      8 in (203 mm) long cylindrical or conical Comp B booster
                      with density of 1.73 g/cc with L/D of greater than or
                      equal to 1
                    </td>
                  </tr>
                  <tr>
                    <td>Propellant diameter and length</td>
                    <td>
                      Sufficient length to permit stabilization or decay of the
                      shock front in the propellant; sufficient diameter to
                      identify rocket motors of a larger diameter susceptible to
                      detonation.
                    </td>
                    <td>
                      <small
                        >Length of 3:1 L/D is required to run beyond effects of
                        the booster (established by testing in the 1950’s and
                        60’s); 4:1 L/D is industry standard for critical
                        diameter tests, enabling discernment of the shock wave
                        generated by the propellant. The diameter was chosen to
                        better predict the detonation susceptibility of motors
                        in the 8-20 inch range versus the NOL Card Gap
                        Test.</small
                      >
                    </td>
                    <td>
                      Solid propellant piece cast in the 32 in (813 mm) long
                      pipe (or two 16 in (406 mm) sections of the pipe unioned
                      with silicon grease)
                    </td>
                  </tr>
                  <tr>
                    <td>Confining medium</td>
                    <td>Provide high confinement</td>
                    <td>
                      <small
                        >Testing has demonstrated that confinement effectively
                        lowers the critical diameter of propellant, increasing
                        its susceptibility to detonation.</small
                      >
                    </td>
                    <td>
                      Steel pipe/ cylinder (ASTM 1026 welded tube drawn over
                      mandrel A513) with 8 in (203 mm) OD and 7 ± 0.03 in (178 ±
                      0.76 mm) ID
                    </td>
                  </tr>
                  <tr>
                    <td>Air Gap</td>
                    <td>Facilitate reaction evaluation</td>
                    <td></td>
                    <td>Air Gap space of 0.0625 in (1.6 mm)</td>
                  </tr>
                  <tr>
                    <td>Witness Plate</td>
                    <td>Evaluate reaction violence</td>
                    <td></td>
                    <td>
                      Steel (ASTM 1020 – 1025) 16 x 16 x 1.5 in (406 x 406 x
                      38.1 mm). The witness plate can’t be directly on a rigid
                      surface.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Measurement of the reaction velocity</td>
                    <td>Velocity pins</td>
                    <td>
                      Data analysis of acquired pin data to determine reaction
                      velocity vs. distance, revealing stable or decaying
                      reaction velocity
                    </td>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Complete penetration
                      <span style="color: a800000;">[subjective]</span> of the
                      witness plate
                    </td>
                  </tr>
                  <tr>
                    <td>Damage to the steel tube</td>
                    <td>Visual post-test examination of the surroundings</td>
                    <td>
                      Complete
                      <span style="color: a800000;">[subjective]</span>
                      fragmentation of the tube
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *Assessments are made to determine if the material propagated
                the shock wave and if so it is a HD 1.1 candidate.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Example Video of Decaying Detonation Velocity, Witness Plate not Completely Penetrated (-)"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88944496?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88944496"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
